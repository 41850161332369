import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi, { HttpBackendOptions } from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import format from "./i18n-format"

//---Using translation
// const resources = {
//     en: {
//         translation: translationEnglish,
//     },
//     es: {
//         translation: translationSpanish,
//     },
//     fr: {
//         translation: translationFrench,
//     },
// }

//---Using different namespaces
/* const resources = {
    en: {
        home: translationEnglish,
        main: translationEnglishSecondFile,
    },
    es: {
        home: translationSpanish,
    },
    fr: {
        home: translationFrench,
    },
} */

export const defaultLanguage = "en";
export const fallbackLanguage = "en";

export const supportedLanguages = [
  { code: "en", name: "English" },
  { code: "de", name: "German" },
];

i18next
.use(initReactI18next)
.use(HttpApi)
.use(LanguageDetector)
.init<HttpBackendOptions>({
  backend: {
    //loadPath: 'https://cdn.appacy.dev/locales/{{lng}}/{{ns}}.json',
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    crossDomain: true,
    requestOptions: { // used for fetch, can also be a function (payload) => ({ method: 'GET' })
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default'
      }
  },
  detection: {
    order: ['navigator', 'cookie', 'localStorage', 'sessionStorage'],//, 'navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'subdomain'],

    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
  },
  load: 'languageOnly',
  supportedLngs: supportedLanguages.map(
    (lang) => lang.code,
    ),
  nonExplicitSupportedLngs: true,
  //lng: defaultLanguage,
  fallbackLng: fallbackLanguage,
  interpolation: {
    escapeValue: false,
    format,
  },
  debug: process.env.NODE_ENV === "development",
});

export function languageCodeOnly(fullyQualifiedCode: String) {
    return fullyQualifiedCode.split("-")[0];
  }

export default i18next;