import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { API } from 'aws-amplify';
import { Collection, TextField, Radio, RadioGroupField, Grid, Divider, Badge, View, Flex, Heading, Image, Card, Text, Tabs, TabItem, Button, useAuthenticator, AccountSettings } from '@aws-amplify/ui-react';

interface ClientProps {
    client_id: string;
    client_name: string;
    logback_uri: string;
    client_logo: string;
    active: boolean;
}

export const Account = () => {
  const {t, i18n } = useTranslation();
  const [index, setIndex] = useState(0);
  const [mfaIndex, setMfaIndex] = useState(0);
  const [mfa0, setMfa0] = useState(false);
  const [mfa1, setMfa1] = useState(true);
  const [mfa2, setMfa2] = useState(true);
  const [mfa3, setMfa3] = useState(true);
  const [value, setValue] = useState('totp');
  const [totpSetupCode, setTotpSetupCode] = useState('1234567890');
  const [totpIsCopied, setTotpIsCopied] = useState(false);
  const {user, signOut} = useAuthenticator((context) => [context.user]);
  const [clients, setClients] = useState([]);

  function setMfa(i: number) {
    console.log(i);
    switch (i) {
        case 0:
            setMfaIndex(i);
            setMfa1(true);
            setMfa2(true);
            setMfa3(true);
            break;
        case 1:
            setMfaIndex(i);
            setMfa1(false);
            setMfa2(true);
            setMfa3(true);
            break;
        case 2:
            setMfaIndex(i);
            setMfa1(true);
            setMfa2(false);
            setMfa3(true);
            break;
        case 3:
            setMfaIndex(i);
            setMfa1(true);
            setMfa2(true);
            setMfa3(false);
            break;
        default:
            setMfaIndex(0);
            setMfa1(true);
            setMfa2(true);
            setMfa3(true);
            break;
    }
  }

  function getClients() {
    const apiName = 'appacyIdentityBrokerApi';
    const path = '/clients';
    const init = {
            }
    API
        .get(apiName, path, init)
        .then(response => {
            let sorted = response.sort((a: ClientProps, b: ClientProps) => (b.active as any) - (a.active as any))
            setClients(sorted);
            console.log(sorted);
        })
        .catch(err => {
            console.log(err);
        });
  };

  useEffect(() => {
    getClients();
  }, []);

  const handleDeleteAccount = () => {
    alert('user has been successfully deleted')
  }

  const handleChangePassword = () => {
    alert('password is successfully changed!')
  }

async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(totpSetupCode)
      .then(() => {
        // If successful, update the isCopied state value
        setTotpIsCopied(true);
        setTimeout(() => {
          setTotpIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  function CustomWarning({ onCancel, onConfirm, isDisabled } : {onCancel:any, onConfirm:any, isDisabled:any}) {
  const [valuew, setValuew] = useState('');

  const handleChange = (event:any) => {
    event.preventDefault();

    const { value } = event.target;
    setValuew(value);
  };

  return (
    <form>
      <Flex direction="column">
        <Text variation="warning">
          {t('SSO.USERINFO.DELETEACCOUNT.WARNING')}
        </Text>
        <TextField
          labelHidden
          label={t('SSO.USERINFO.DELETEACCOUNT.CONFIRM_BUTTON')}
          placeholder={t('SSO.USERINFO.DELETEACCOUNT.DELETE') as string}
          onChange={handleChange}
          value={valuew}
        />
        <Button onClick={onCancel}>{t('SSO.USERINFO.DELETEACCOUNT.CANCEL')}</Button>
        <Button
          onClick={onConfirm}
          variation="primary"
          isDisabled={isDisabled || valuew !== t('SSO.USERINFO.DELETEACCOUNT.DELETE')}
        >
          {t('SSO.USERINFO.DELETEACCOUNT.CONFIRM_BUTTON')}
        </Button>
      </Flex>
    </form>
  );
}

const deleteUserComponents = {
    DeleteButton: (props: any) => (
      <AccountSettings.DeleteUser.DeleteButton {...props}>{t('SSO.USERINFO.DELETEACCOUNT_BUTTON')}</AccountSettings.DeleteUser.DeleteButton>
    ),
    WarningView: CustomWarning
  }

  const changePasswordComponents = {
    CurrentPasswordField: (props: any) => (
      <AccountSettings.ChangePassword.CurrentPasswordField
        {...props} 
        label={t('SSO.CHANGEPASSWORD.CURRENT_LABEL')}
      />
    ),
    NewPasswordField: (props: any) => (
      <AccountSettings.ChangePassword.NewPasswordField
        {...props} 
        label={t('SSO.CHANGEPASSWORD.NEW_LABEL')} 
      />
    ),
    ConfirmPasswordField: (props: any) => (
      <AccountSettings.ChangePassword.ConfirmPasswordField
        {...props} 
        label={t('SSO.CHANGEPASSWORD.CONFIRM_LABEL')}
      />
    ),
    ErrorMessage: ({ children } : {children: any}) => (
      <>
        <Heading>{t('SSO.CHANGEPASSWORD.ERROR_HEADING')}</Heading>
        <Text variation="warning">{children}</Text>
      </>
    ),
    SubmitButton: (props: any) => (
        <AccountSettings.ChangePassword.SubmitButton {...props}>
            {t('SSO.CHANGEPASSWORD.SUBMIT_BUTTON')}
        </AccountSettings.ChangePassword.SubmitButton>
    )
  }

  return (
    <View className='accounts-container'>
        <View className='accounts-wrapper'>
            <View className="brand">
                <Image
                alt="Appacy Logo"
                src="https://cdn.appacy.dev/images/appacy/appacy-rnd.svg"
                />
            </View>
            <View className='accounts-tabs'>
                <Tabs 
                    currentIndex={index} 
                    onChange={(i: string | number) => setIndex(Number(i))}
                    spacing="relative"
                    justifyContent="space-evenly"
                    indicatorPosition="top"
                >
                    <TabItem title={t('SSO.APPS.TITLE')}>
                        <View className="accounts-tab">
                            <View className="card-client-wrapper">
                                <Collection
                                    items={clients}
                                    type="list"
                                    direction="column"
                                    gap="20px"
                                    wrap="wrap"
                                    justifyContent="center"
                                    alignItems="center"
                                    >
                                    {(item: ClientProps, index) => (
                                        
                                        <Card
                                            key={index}
                                            variation="elevated"
                                            width="100%"
                                        >
                                            <Grid
                                                templateColumns="1fr 1fr"
                                                templateRows="1fr"
                                                gap="0.5rem"
                                            >       
                                                <Image
                                                    alt={item.client_name + ' logo'}
                                                    src={item.client_logo}
                                                    height="100px"
                                                />
                                                <View>
                                                    <Heading padding="medium">{item.client_name}</Heading>
                                                    <Button isDisabled={!item.active} variation="primary" isFullWidth onClick={() => window.open(item.logback_uri, "_blank")}>
                                                        {item.active ? t('SSO.APPS.LAUNCHAPP_BUTTON') : t('SSO.APPS.LAUNCHAPP_BUTTON_UNAVAILABLE')}
                                                    </Button>
                                                </View>
                                            </Grid>
                                        </Card>
                                    )}
                                </Collection>
                            </View>
                        </View>
                    </TabItem>
                    <TabItem title={t('SSO.USERINFO.TITLE')}>
                        <View className="accounts-tab">
                            <h3>{t('SSO.GENERAL.GREETING').replace('{email}', (user && user.attributes) ? user.attributes.email : '')}</h3>
                            <AccountSettings.DeleteUser onSuccess={handleDeleteAccount} components={deleteUserComponents} />
                        </View>
                    </TabItem>
                    <TabItem title={t('SSO.CHANGEPASSWORD.TITLE')}>
                        <View className="accounts-tab">
                            <AccountSettings.ChangePassword onSuccess={handleChangePassword} components={changePasswordComponents}/>
                        </View>
                    </TabItem>
                    <TabItem title={t('SSO.TWOFACTOR.TITLE')}>
                        <View className="accounts-tab twofactor">
                            <Tabs 
                                spacing="relative"
                                currentIndex={mfaIndex} 
                                onChange={(i: string | number) => setMfa(Number(i))}
                            >
                                <TabItem isDisabled={mfa0} title={'1. ' + t('SSO.TWOFACTOR.TWOFACTORMETHOD_TAB')}>
                                    <View className="accounts-tab">
                                        <RadioGroupField
                                            label={t('SSO.TWOFACTOR.CHOOSE_LABEL')}
                                            name="mfa-method"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                        >
                                            <Radio value="totp">TOTP</Radio>
                                            <Radio value="sms">SMS</Radio>
                                            <Radio value="none">{t('SSO.GENERAL.NONESELECTED')}</Radio>
                                        </RadioGroupField>
                                        <Button isFullWidth onClick={() => setMfa(1)}>
                                            {t('SSO.GENERAL.NEXT_BUTTON')}
                                        </Button>
                                    </View>
                                </TabItem>
                                <TabItem isDisabled={mfa1} title={'2. ' + t('SSO.TWOFACTOR.REQUESTCODE_TAB')}>
                                    <View className="accounts-tab">
                                        <Button isFullWidth onClick={() => setMfa(2)}>
                                            {t('SSO.TWOFACTOR.REQUESTCODE_BUTTON')}
                                        </Button>
                                    </View>
                                </TabItem>
                                <TabItem isDisabled={mfa2} title={'3. ' + t('SSO.TWOFACTOR.SETUP_TAB')}>
                                    <View className="accounts-tab">
                                        <Button isFullWidth onClick={handleCopyClick}>
                                            {totpIsCopied ? t('SSO.GENERAL.COPIED') : t('SSO.GENERAL.COPYCODE')}
                                        </Button>
                                        <Button isFullWidth onClick={() => setMfa(3)}>
                                            {t('SSO.GENERAL.NEXT_BUTTON')}
                                        </Button>
                                    </View>
                                </TabItem>
                                <TabItem isDisabled={mfa3} title={'4. ' + t('SSO.TWOFACTOR.VERIFYCODE_TAB')}>
                                    <View className="accounts-tab">
                                        <Button isFullWidth onClick={() => setMfa(0)}>
                                            {t('SSO.TWOFACTOR.VERIFYCODE_BUTTON')}
                                        </Button>
                                    </View>
                                </TabItem>
                            </Tabs>
                        </View>
                    </TabItem>
                </Tabs>
            </View>
            <View className="footer-signout">
                <Button onClick={signOut}>
                    {t('AUTH.GENERAL.SIGNOUT_BUTTON')}
                </Button>
            </View>
            <View className="footer">
                <Text >
                    128 City Road, London, EC1V 2NX
                </Text>
                <Text >
                    &copy; {t('SSO.LEGAL.COPYRIGHT')}
                </Text>
            </View>
        </View>
    </View>
  );
};