import React, {useEffect} from 'react';
import { Amplify, I18n } from 'aws-amplify';
import {Account} from './Account';
import { withAuthenticator, Authenticator, WithAuthenticatorProps, View, Image, Text, translations } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useTranslation } from "react-i18next";

/* interface Props extends WithAuthenticatorProps {
  isPassedToWithAuthenticator: boolean;
}

function App({ isPassedToWithAuthenticator, signOut, user }: Props) {
  if (!isPassedToWithAuthenticator) {
    throw new Error(`isPassedToWithAuthenticator was not provided`);
  }

  return (
    <>
		<div className="row brand">
			<img src="https://cdn.appacy.dev/images/appacy/appacy-rnd.svg" alt="" />
		</div>
		<div className="row">
			<h1>Hello {user?.attributes?.email}</h1>
			<button onClick={signOut}>Sign out</button>
		</div>
	</>
  );
}

export default withAuthenticator(App);

export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
} */

function App() {
	I18n.putVocabularies(translations);
	I18n.setLanguage('en');

	I18n.putVocabularies({
	en: {
		'Sign In with Facebook': '',
		'Sign In with Amazon': 'A',
		'Sign In with Google': 'G'
	}
	});
	
	const {t, i18n } = useTranslation();
	useEffect(() => {
    document.dir = i18n.dir();
    document.documentElement.lang = i18n.language;

    //document.title = i18n.t("document_title");
  }, [i18n, i18n.language]);

	const components = {
		Header() {
			return (
			<View className="auth-brand">
				<Image
				alt="Appacy Logo"
				src="https://cdn.appacy.dev/images/appacy/appacy-rnd.svg"
				/>
			</View>
			);
		},
		Footer() {
			return (
			<View className="auth-footer">
				<Text >
                    128 City Road, London, EC1V 2NX
                </Text>
				<Text >
					&copy; {t('SSO.LEGAL.COPYRIGHT')}
				</Text>
			</View>
			);
		}
	}
	return (
		<>
			<Authenticator components={components}>
				{({ signOut, user }) => (
					<>
						<Account />
					</>
				)}
			</Authenticator>
		</>
	);
}

export default App;
